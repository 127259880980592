<template>
  <PageContainer>
    <PageGrid
      :layout="{
        sm: PAGE_GRID_LAYOUT.COLS_1,
        md: PAGE_GRID_LAYOUT.COLS_1_2,
        lg: PAGE_GRID_LAYOUT.COLS_1_2,
      }"
      :gap="{
        default: PAGE_GRID_GAP_SIZE.LG,
        sm: PAGE_GRID_GAP_SIZE.LG,
        md: PAGE_GRID_GAP_SIZE.LG,
        lg: PAGE_GRID_GAP_SIZE.LG,
      }"
    >
      <PageGridColumn
        class="grid grid-cols-[minmax(100px,20%),1fr] items-end gap-6 md:col-span-2 md:gap-10"
      >
        <div class="mt-2">
          <PersonProfileImage :person="person" :render-above-the-fold="true" />
        </div>
        <div>
          <h1 class="text-2xl md:text-5xl" v-text="person.name" />
          <div class="text-lg md:text-2xl" v-text="occupation" />
        </div>
      </PageGridColumn>

      <PageGridColumn>
        <PageSectionHeading :title="t('section.title.about')" />
        <PersonInfo :person="person" />
      </PageGridColumn>

      <PageGridColumn v-if="person.biography">
        <PageSectionHeading :title="t('section.title.biography')" />
        <div
          class="prose dark:prose-invert whitespace-pre-line"
          v-html="person.biography"
        />
      </PageGridColumn>

      <PageGridColumn v-if="person.images.length" class="md:col-span-2">
        <PageSectionHeading :title="t('section.title.gallery')" />
        <PersonGallery :name="person.name" :images="person.images" />
      </PageGridColumn>

      <PageGridColumn class="grid grid-cols-1 md:col-span-2">
        <div
          v-if="person.actedIn.data?.length"
          class="space-y-6"
          :class="{ 'order-last pt-6': isDirector }"
        >
          <PageSectionHeading
            :title="t('section.title.movies', { personName: person.name })"
          />
          <MovieCardsHorizontal
            :movies="person.actedIn.data"
            :link-params="[]"
          />
        </div>

        <div
          v-if="person.directedIn.data?.length"
          class="space-y-6"
          :class="{ 'order-first': isDirector }"
        >
          <PageSectionHeading
            :title="t('section.title.directed', { personName: person.name })"
          />
          <MovieCardsHorizontal
            :movies="person.directedIn.data"
            :link-params="[]"
          />
        </div>
      </PageGridColumn>

      <PageGridColumn v-if="person.relatedPersons.length" class="md:col-span-2">
        <PageSectionHeading
          :title="
            t('section.title.relatedPersons', { personName: person.name })
          "
        />
        <PersonCardsHorizontal :persons="relatedPersons" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  person: Person
}

const { person } = defineProps<Props>()
const { t } = useI18n()
const { $gtm } = useNuxtApp()

const isDirector = computed(
  () => person.actedIn.data.length < person.directedIn.data.length
)

const occupation = computed(() => {
  const { actedIn, directedIn } = person

  const occupations: string[] = []
  if (actedIn.data.length) {
    occupations.push(t('actor'))
  }

  if (directedIn.data.length) {
    occupations.push(t('director'))
  }

  return isDirector.value
    ? occupations.reverse().join(', ')
    : occupations.join(', ')
})

// Prioritize persons with profile image
const relatedPersons = computed(() =>
  [...person.relatedPersons].sort(
    (a, b) => +!!b.profileImage - +!!a.profileImage
  )
)

usePageMetas({
  title: person.name,
  ...person.meta,
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPagePerson',
})
</script>

<i18n>
de:
  page:
    description: "Jetzt bei kinoheld Kinotickets in der Nähe von {cityName} schnell und bequem online kaufen."
  section:
    title:
      about: "Über"
      biography: "Biographie"
      movies: "Filme mit {personName}"
      directed: "Regie durch {personName}"
      gallery: "Galerie"
      relatedPersons: "Personen im Zusammenhang mit {personName}"
  director: "Regisseur:in"
  actor: "Schauspieler:in"
es:
  page:
    description: "Compra tus entradas de cine en {cityName} de forma rápida y sencilla en entradas."
  section:
    title:
      about: "Información"
      biography: "Biografía"
      movies: "Películas con {personName}"
      directed: "Dirigido por {personName}"
      gallery: "Galería"
      relatedPersons: "Personas relacionadas con {personName}"
  director: "Director"
  actor: "Actor"
</i18n>
