<template>
  <div
    ref="container"
    data-person-cards-horizontal
    class="group-data-[default-horizontal-spacing=true]:container-bleed space-y-4"
  >
    <HorizontalMediaScroller
      :slides="persons"
      :per-page="slidesVisible"
      :classes="{
        slide: cssClasses.slide,
      }"
      image-selector="[data-image]"
    >
      <template #default="{ slide: person, index }">
        <PersonCard
          :key="person.urlSlug"
          :person="person"
          :image-config="imageConfig"
          :render-above-the-fold="renderAboveTheFold && index <= 3"
        />
      </template>
    </HorizontalMediaScroller>
  </div>
</template>

<script setup lang="ts">
import type { Person } from '#gql/defaults'

interface Props {
  persons: Person[]
  imageConfig?: ImageConfig
  renderAboveTheFold?: boolean
}

withDefaults(defineProps<Props>(), {
  renderAboveTheFold: false,
  imageConfig: undefined,
})

const container = ref(null)
const { width } = useElementSize(container)

const slidesVisible = computed(() => {
  if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_3XL) {
    return 6
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_2XL) {
    return 5
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_XL) {
    return 4
  } else {
    return 3
  }
})

const cssClasses = {
  container:
    'flex group relative px-3 sm:px-6 xl:px-8 2xl:px-8 overflow-hidden',
  slide:
    'flex flex-col grow-0 shrink-0 px-1 sm:px-2 basis-1/3 @xl:basis-1/4 @2xl:basis-1/5 @3xl:basis-1/6',
}

defineOptions({
  name: 'PersonCardsHorizontal',
})
</script>
