<template>
  <PersonFetch
    fetch-key="person"
    :fetch-params="{ urlSlug: route.params.personSlug as string }"
    :throw-error="true"
  >
    <template #default="{ person, pending }">
      <NuxtPage v-if="!pending && person" :person="person" />
    </template>
  </PersonFetch>
</template>

<script lang="ts" setup>
const route = useRoute()

defineI18nRoute({
  paths: {
    en: '/person/[personSlug]',
    de: '/person/[personSlug]',
    es: '/persona/[personSlug]',
  },
})

defineOptions({
  name: 'PagePersonSlug',
})
</script>
