<template>
  <div>
    <GalleryVertical
      :slides="slides"
      :allow-zoom="true"
      :pattern="GALLERY_PATTERN.AUTO"
      :image-size="GALLERY_IMAGE_SIZE.SM"
      :image-format="GALLERY_IMAGE_FORMAT.ORIGINAL"
      :classes="{
        title: 'hidden',
        description: '!hidden',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Image } from '#gql/default'
interface Props {
  images: Image[]
  name: string
}

const props = defineProps<Props>()
const { t } = useI18n()

const slides = computed(() => {
  const slides = props.images.map(({ license, credit }, index) => ({
    image: getImage(props, `images[${index}]`, props.name),
    title: `<strong>${t('license')}:</strong> ${license}`,
    description: `<strong>${t('credit')}:</strong> ${credit}`,
  }))

  const portraitSlides = slides.filter(
    (slide) => slide.image.format === IMAGE_FORMAT.PORTRAIT
  )
  const landscapeSlides = slides.filter(
    (slide) => slide.image.format === IMAGE_FORMAT.LANDSCAPE
  )
  const widescreenSlides = slides.filter(
    (slide) => slide.image.format === IMAGE_FORMAT.WIDESCREEN
  )

  return [...portraitSlides, ...landscapeSlides, ...widescreenSlides]
})

defineOptions({
  name: 'PersonGallery',
})
</script>

<i18n>
de:
  license: "Lizenz"
  credit: "Quelle"
es:
  license: "Licencia"
  credit: "Fuente"
</i18n>
