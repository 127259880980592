<template>
  <ul class="space-y-4">
    <li
      v-for="({ label, value }, i) in [birthDate, placeOfBirth, deathDate]"
      :key="i"
    >
      <template v-if="value">
        <strong class="block pb-0.5 uppercase" v-text="label" />
        <span v-text="value" />
      </template>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  person: Person
}

const props = defineProps<Props>()
const { t, d } = useI18n()

const birthDate = computed(() => ({
  label: t('label.birthdate'),
  value: props.person.birthdate
    ? d(new Date(props.person.birthdate), {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
    : null,
}))

const deathDate = computed(() => ({
  label: t('label.deathdate'),
  value: props.person.deathdate
    ? d(new Date(props.person.deathdate), {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
    : null,
}))

const placeOfBirth = computed(() => ({
  label: t('label.placeOfBirth'),
  value: props.person.placeOfBirth,
}))

defineOptions({
  name: 'PersonInfo',
})
</script>

<i18n>
de:
  label:
    birthdate: "Geburtsdatum"
    deathdate: "Todesdatum"
    placeOfBirth: "Geburtsort"
es:
  label:
    birthdate: "Fecha de nacimiento"
    deathdate: "Fecha de fallecimiento"
    placeOfBirth: "Lugar de nacimiento"
</i18n>
