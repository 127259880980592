<template>
  <slot :person="person" :pending="pending" />
</template>

<script lang="ts" setup>
import type { FetchPersonQueryVariables } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchPersonQueryVariables
  throwError?: boolean
}

const COMPONENT_NAME = 'PersonFetch'
const props = withDefaults(defineProps<Props>(), {
  throwError: false,
})

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchPerson(props.fetchParams),
  { immediate: false }
)

await execute()

if (!data.value?.person?.id && props.throwError) {
  showError({
    statusCode: 404,
    statusMessage: 'personNotFound',
  })
}

const person = computed(() => data.value?.person)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
