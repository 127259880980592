<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPagePerson v-else :person />
  </div>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  person: Person
}

defineProps<Props>()

const { page } = useCms()

defineOptions({
  name: 'PagePersonSlugIndex',
})
</script>
